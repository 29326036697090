export interface IPermisionsPayload {
  screen: EPermissionScreen;
  types: EPermissionType[];
}

export enum EParentMenu {
  MENU_TRANG_CHU = 'MENU_TRANG_CHU',
  MENU_DON_HANG = 'MENU_DON_HANG',
  MENU_TAI_KHOAN = 'MENU_TAI_KHOAN',
  MENU_SAN_PHAM = 'MENU_SAN_PHAM',
  MENU_UU_DAI = 'MENU_UU_DAI',
  MENU_THONG_BAO = 'MENU_THONG_BAO',
  MENU_LOAI_PHIEU = 'MENU_LOAI_PHIEU',
  MENU_NOI_DUNG = 'MENU_NOI_DUNG',
  MENU_KHO = 'MENU_KHO',
  MENU_GIA = 'MENU_GIA',
  MENU_CAU_HINH = 'MENU_CAU_HINH',
  MENU_BAO_CAO = 'MENU_BAO_CAO',
}

export enum EPermissionScreen {
  MENU_TRANG_CHU = 'MENU_TRANG_CHU',
  MENU_DON_HANG = 'MENU_DON_HANG',
  TAO_DON_HANG = 'TAO_DON_HANG',
  DON_HANG = 'DON_HANG',
  KHACH_TRA_HANG = 'KHACH_TRA_HANG',
  MENU_TAI_KHOAN = 'MENU_TAI_KHOAN',
  KHACH_HANG = 'KHACH_HANG',
  HANG_TAI_KHOAN = 'HANG_TAI_KHOAN',
  MENU_SAN_PHAM = 'MENU_SAN_PHAM',
  SAN_PHAM = 'SAN_PHAM',
  DANH_MUC_SAN_PHAM = 'DANH_MUC_SAN_PHAM',
  NHOM_SAN_PHAM = 'NHOM_SAN_PHAM',
  MENU_KHO = 'MENU_KHO',
  KHO_HANG = 'KHO_HANG',
  NHA_CUNG_CAP = 'NHA_CUNG_CAP',
  DON_DAT_HANG = 'DON_DAT_HANG',
  NHAP_HANG = 'NHAP_HANG',
  DON_TRA_NCC = 'DON_TRA_NCC',
  DIEU_CHUYEN = 'DIEU_CHUYEN',
  MENU_UU_DAI = 'MENU_UU_DAI',
  SALE = 'SALE',
  FLASHSALE = 'FLASHSALE',
  QUA_TANG = 'QUA_TANG',
  MUA_NHIEU = 'MUA_NHIEU',
  VOUCHER = 'VOUCHER',
  MENU_LOAI_PHIEU = 'MENU_LOAI_PHIEU',
  PHIEU_THU = 'PHIEU_THU',
  PHIEU_CHI = 'PHIEU_CHI',
  LOAI_PHIEU_THU = 'LOAI_PHIEU_THU',
  LOAI_PHIEU_CHI = 'LOAI_PHIEU_CHI',
  MENU_THONG_BAO = 'MENU_THONG_BAO',
  MENU_NOI_DUNG = 'MENU_NOI_DUNG',
  BANNER = 'BANNER',
  ONBOARD = 'ONBOARD',
  FAQ = 'FAQ',
  MENU_CAU_HINH = 'MENU_CAU_HINH',
  HDSD = 'HDSD',
  MENU_BAO_CAO = 'MENU_BAO_CAO',
  BAO_CAO_KHO = 'BAO_CAO_KHO',
  BAO_CAO_NHAP_HANG = 'BAO_CAO_NHAP_HANG',
  BAO_CAO_BAN_HANG = 'BAO_CAO_BAN_HANG',
  MENU_EXCEL = 'MENU_EXCEL',
  BAO_CAO_NHAP_HANG_CHI_TIET = 'BAO_CAO_NHAP_HANG_CHI_TIET',
  BAO_CAO_TON_KHO = 'BAO_CAO_TON_KHO',
  BAO_CAO_TON_KHO_THEO_DATE = 'BAO_CAO_TON_KHO_THEO_DATE',
  BAO_CAO_SO_KHO = 'BAO_CAO_SO_KHO',
  BAO_CAO_TON_KHO_DUOI_DINH_MUC = 'BAO_CAO_TON_KHO_DUOI_DINH_MUC',
  BAO_CAO_TON_KHO_VUOT_DINH_MUC = 'BAO_CAO_TON_KHO_VUOT_DINH_MUC',
  BAO_CAO_XUAT_NHAP_TON = 'BAO_CAO_XUAT_NHAP_TON',
  BAO_CAO_THANH_TOAN_NHAP_HANG = 'BAO_CAO_THANH_TOAN_NHAP_HANG',
  BAO_CAO_TAI_CHINH = 'BAO_CAO_TAI_CHINH',
  BAO_CAO_LAI_LO = 'BAO_CAO_LAI_LO',
  BAO_CAO_SO_QUY = 'BAO_CAO_SO_QUY',
  BAO_CAO_CONG_NO_KHACH_HANG = 'BAO_CAO_CONG_NO_KHACH_HANG',
  BAO_CAO_CONG_NO_NHA_CUNG_CAP = 'BAO_CAO_CONG_NO_NHA_CUNG_CAP',
  BAO_CAO_KHACH_HANG = 'BAO_CAO_KHACH_HANG',
  BAO_CAO_DOANH_THU_CUA_HANG = 'BAO_CAO_DOANH_THU_CUA_HANG',
  BAO_CAO_GIAO_HANG = 'BAO_CAO_GIAO_HANG',
  BAO_CAO_TRA_HANG = 'BAO_CAO_TRA_HANG',
  BAO_CAO_THANH_TOAN_DON_HANG = 'BAO_CAO_THANH_TOAN_DON_HANG',
  BAO_CAO_DON_HANG = 'BAO_CAO_DON_HANG',
  MENU_VAN_CHUYEN = 'MENU_VAN_CHUYEN',
  KET_NOI_DOI_TAC = 'KET_NOI_DOI_TAC',
  DIEU_KHOAN_VA_CHINH_SACH = 'DIEU_KHOAN_VA_CHINH_SACH',
  //
  SO_QUY = 'SO_QUY',
}

export enum EPermissionType {
  ADD = 'ADD',
  VIEW = 'VIEW',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  CHANGE_STATUS = 'CHANGE_STATUS', // thay đổi trạng thái
  EXPORT = 'EXPORT', // xuất excel
  IMPORT = 'IMPORT', // nhập excel
  PRINT = 'PRINT', // In
  BILL = 'BILL', // Phiếu thu chi
  DEBT = 'DEBT', // Lịch sử công nợ
  BY_TIME = 'BY_TIME',
  BY_CUSTOMER = 'BY_CUSTOMER',
  BY_SUPPLIER = 'BY_SUPPLIER',
  BY_PRODUCT = 'BY_PRODUCT',
  BY_WAREHOUSE = 'BY_WAREHOUSE',
  BY_IMPORT = 'BY_IMPORT', //Theo đơn nhập
  BY_EMPLOYEE = 'BY_EMPLOYEE',
  BY_SOURCE = 'BY_SOURCE',
  BY_ORDER = 'BY_ORDER',
  BY_PARTNER = 'BY_PARTNER', //Theo đối tác vận chuyển
}
