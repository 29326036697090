import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { EPermissionScreen, EPermissionType } from 'src/utils/permissions';
import { ROUTE_CONFIG } from './helpers';

const LoginScreen = lazy(() => import('../screens/login'));
const FogotPasswordScreen = lazy(() => import('../screens/login/components/FogotPassword'));

const DashboardScreen = lazy(() => import('../screens/dasboard'));

const TermsOfUse = lazy(() => import('../screens/preview/terms'));
const PrivacyPolicy = lazy(() => import('../screens/preview/privacy'));

const NotificationScreen = lazy(() => import('../screens/notification'));
const NotificationScreenAdd = lazy(() => import('../screens/notification/addNotification'));
const NotificationScreenUpdate = lazy(() => import('../screens/notification/updateNotification'));

const BannerScreen = lazy(() => import('../screens/content-manager/banner'));

const FAQScreen = lazy(() => import('../screens/content-manager/faq'));
const FAQScreenAdd = lazy(() => import('../screens/content-manager/faq/components/addFaq'));
const FAQScreenDetail = lazy(() => import('../screens/content-manager/faq/components/detailFaq'));
const TermsOfUseScreen = lazy(() => import('../screens/content-manager/terms-of-use'));

// Quản lý đơn hàng
const OrderManagerScreen = lazy(() => import('../screens/order-manager'));
const AddOrderScreen = lazy(() => import('../screens/order-manager/components/AddOrder'));
const DetailOrderFormScreen = lazy(() => import('../screens/warehouse-manager/order-form/components/DetailOrderForm'));
const EditOrderFormScreen = lazy(() => import('../screens/warehouse-manager/order-form/components/AddOrderForm'));

const DetailOrderScreen = lazy(() => import('../screens/order-manager/components/DetailOrder'));
const EditOrderScreen = lazy(() => import('../screens/order-manager/components/EditOrder'));

const CustomerReturnProductScreen = lazy(() => import('../screens/customer-return-product'));
const AddCustomerReturnProductScreen = lazy(
  () => import('../screens/customer-return-product/components/AddReturnProduct'),
);
const DetailCustomerReturnProductScreen = lazy(
  () => import('../screens/customer-return-product/components/DetailReturnProduct'),
);

//warehouse
const WarehouseScreen = lazy(() => import('../screens/warehouse-manager/warehouse'));
const PaymentFormScreen = lazy(() => import('../screens/warehouse-manager/payment-form'));

//supplier
const SupplierScreen = lazy(() => import('../screens/warehouse-manager/supplier'));
const DetailSupplierScreen = lazy(() => import('../screens/warehouse-manager/supplier/components/DetailSupplier'));
const DetailSupplierBillScreen = lazy(
  () => import('../screens/warehouse-manager/supplier/components/DetailBillReceiptOrPaySlip'),
);
//nhập hàng
const ImportProductsScreen = lazy(() => import('../screens/warehouse-manager/import-products'));
const ImportProductsCoppy = lazy(
  () => import('../screens/warehouse-manager/import-products/components/AddImportProductsCopy'),
);
const AddImportProductsScreen = lazy(
  () => import('../screens/warehouse-manager/import-products/components/AddImportProducts'),
);
const DetailImportProductsScreen = lazy(
  () => import('../screens/warehouse-manager/import-products/components/DetailImportProducts'),
);
//Điều chuyển hàng
const TransportProductsScreen = lazy(() => import('../screens/warehouse-manager/transport-products'));
const AddTransportProductsScreen = lazy(
  () => import('../screens/warehouse-manager/transport-products/components/AddTransportProducts'),
);
const CopyTransportProductsScreen = lazy(
  () => import('../screens/warehouse-manager/transport-products/components/CopyTransportProduct'),
);
const DetailTransportProductsScreen = lazy(
  () => import('../screens/warehouse-manager/transport-products/components/DetailTransportProducts'),
);

const PrintBarcodeTransportScreen = lazy(
  () => import('../screens/warehouse-manager/transport-products/components/PrintBarcodeTransport'),
);
const CheckQuantityTransportProductsScreen = lazy(
  () => import('../screens/warehouse-manager/transport-products/components/CheckQuantityTransportProduct'),
);
const EditTransportProductsScreen = lazy(
  () => import('../screens/warehouse-manager/transport-products/components/EditTransportProducts'),
);
//
const CreateAndPrintBarcode = lazy(
  () => import('../screens/warehouse-manager/import-products/components/CreateAndPrintBarcode'),
);
const ReturnSupplier = lazy(() => import('../screens/warehouse-manager/import-products/components/ReturnSupplier'));

// đơn đặt hàng
const OrderFormScreen = lazy(() => import('../screens/warehouse-manager/order-form'));
const AddOrderFormScreen = lazy(() => import('../screens/warehouse-manager/order-form/components/AddOrderForm'));

const SaleScreen = lazy(() => import('../screens/incentive-manager/sale'));
const AddSaleScreen = lazy(() => import('../screens/incentive-manager/sale/components/addSale'));
const DetailSaleScreen = lazy(() => import('../screens/incentive-manager/sale/components/detailSale'));

// Flashsale
const FlashsaleScreen = lazy(() => import('../screens/incentive-manager/flash-Sale'));
const FlashsaleAddScreen = lazy(() => import('../screens/incentive-manager/flash-Sale/components/addFlashSale'));
const FlashsaleDetailScreen = lazy(() => import('../screens/incentive-manager/flash-Sale/components/DetailFlashSale'));

// Voucher
const VoucherIncentiveScreen = lazy(() => import('../screens/incentive-manager/voucher'));
const AddVoucherIncentive = lazy(() => import('../screens/incentive-manager/voucher/components/addVoucher'));
const DetailVoucherIncentive = lazy(() => import('../screens/incentive-manager/voucher/components/detailVoucher'));

// incentive-manager-give-a-gift
const GiveAGiftScreen = lazy(() => import('../screens/incentive-manager/give-a-gift'));
const AddGiveAGiftScreen = lazy(() => import('../screens/incentive-manager/give-a-gift/components/addGift'));
const DetailGiveAGiftScreen = lazy(() => import('../screens/incentive-manager/give-a-gift/components/detailGift'));
// buy more
const BuyMoreScreen = lazy(() => import('../screens/incentive-manager/buy-more'));
const AddBuyMoreScreen = lazy(() => import('../screens/incentive-manager/buy-more/components/addBuyMore'));
const DetailBuyMore = lazy(() => import('../screens/incentive-manager/buy-more/components/detailBuyMore'));

// Sản phẩm
const ProductScreen = lazy(() => import('../screens/product-manager/product'));
const AddNewProductScreen = lazy(() => import('../screens/product-manager/product/components/AddNewProduct'));
const DetailProductScreen = lazy(() => import('../screens/product-manager/product/components/DetailProduct'));
const EditDetailProductScreen = lazy(() => import('../screens/product-manager/product/components/EditDetailProduct'));
const CopyProductScreen = lazy(() => import('../screens/product-manager/product/components/CopyProduct'));

// Nhóm sản phẩm
const ProductGroupScreen = lazy(() => import('../screens/product-manager/product-group'));
const AddGroupProductScreen = lazy(() => import('../screens/product-manager/product-group/components/AddProductGroup'));
const DetailGroupProductScreen = lazy(
  () => import('../screens/product-manager/product-group/components/DetailProductGroup'),
);

// Danh mục
const ProductManagerScreen = lazy(() => import('../screens/product-manager/product-category'));

// HDSD
const Instruction = lazy(() => import('../screens/instruction/'));
const AddInstruction = lazy(() => import('../screens/instruction/components/addInstruction'));
const DetailInstruction = lazy(() => import('../screens/instruction/components/detailInstruction'));

// Cấu hình
const ConfigPage = lazy(() => import('../screens/config'));

// Nhóm admin
const GroupAdminScreen = lazy(() => import('../screens/account/group-admin'));

// Admin
const AdminManagerScreen = lazy(() => import('../screens/account/admin'));

// Khách hàng
const AccountCustomerScreen = lazy(() => import('../screens/account/customer'));
const AccountCustomerDetailScreen = lazy(() => import('../screens/account/customer/components/DetailCustomer'));
const CustomerDetailBillScreen = lazy(() => import('../screens/account/customer/components/DetailBill'));

// Hạng tài khoản
const AccountRankScreen = lazy(() => import('../screens/account-rank'));

const OnboardScreen = lazy(() => import('../screens/content-manager/onboard'));

// Barcode
const ProductBarcode = lazy(() => import('../screens/product-manager/product-barcode'));
const AddProductBarcode = lazy(() => import('../screens/product-manager/product-barcode/components/AddProductBarcode'));
const DetailProductBarcode = lazy(
  () => import('../screens/product-manager/product-barcode/components/DetailProductBarcode'),
);
//bill receipt
const ReceiptScreen = lazy(() => import('../screens/bill/receipt'));
const DetailReceipt = lazy(() => import('../screens/bill/receipt/components/detailReceipt'));
//bill payslip
const PaySliptScreen = lazy(() => import('../screens/bill/payslip'));
const DetailPaySlip = lazy(() => import('../screens/bill/payslip/component/detailPaySlip'));
//cash book
const CashBookScreen = lazy(() => import('../screens/bill/cash-book'));

// Báo cáo
const ReportWarehouseScreen = lazy(() => import('../screens/report-manager/report-warehouse'));
const ReportWarehouseInventoryScreen = lazy(
  () => import('../screens/report-manager/report-warehouse/components/inventory/ReportInventory'),
);
const ReportWarehouseInventoryByDateScreen = lazy(
  () => import('../screens/report-manager/report-warehouse/components/inventory-by-date/ReportInventoryByDate'),
);

const ReportWarehouseBookScreen = lazy(
  () => import('../screens/report-manager/report-warehouse/components/warehouse-book/ReportByWarehouseBook'),
);

const ReportInventoryBelowNormScreen = lazy(
  () => import('../screens/report-manager/report-warehouse/components/below-norm/ReportInventoryBelowNorm'),
);

const ReportInventoryAboveNormScreen = lazy(
  () => import('../screens/report-manager/report-warehouse/components/above-norm/ReportInventoryAboveNorm'),
);

const ReportExportImportInventoryScreen = lazy(
  () =>
    import('../screens/report-manager/report-warehouse/components/inventory-export-import/ReportExportImportInventory'),
);

//Báo cáo doanh thu
const ReportSalesScreen = lazy(() => import('../screens/report-manager/report-sales'));
const ReportSalesByTime = lazy(
  () => import('../screens/report-manager/report-sales/report-sales-by/ReportSalsesByTime'),
);
const ReportSalesByStaff = lazy(
  () => import('../screens/report-manager/report-sales/report-sales-by/ReportSalesByStaff'),
);
const ReportSalesByProduct = lazy(
  () => import('../screens/report-manager/report-sales/report-sales-by/ReportSalesByProduct'),
);
const ReportSalesByOrder = lazy(
  () => import('../screens/report-manager/report-sales/report-sales-by/ReportSalesByOrder'),
);
const ReportSalesByWarehouse = lazy(
  () => import('../screens/report-manager/report-sales/report-sales-by/ReportSalesByWarehouse'),
);
const ReportSalesBySource = lazy(
  () => import('../screens/report-manager/report-sales/report-sales-by/ReportSalesBySource'),
);
const ReportSalesByCustomer = lazy(
  () => import('../screens/report-manager/report-sales/report-sales-by/ReportSalesByCustomer'),
);
const ImportProductScreen = lazy(() => import('../screens/report-manager/import-product'));
const ReportTimeScreen = lazy(() => import('../screens/report-manager/import-product/report-import/ReportTime'));
const ReportSupplierScreen = lazy(
  () => import('../screens/report-manager/import-product/report-import/ReportSupplier'),
);
const ReportProductScreen = lazy(() => import('../screens/report-manager/import-product/report-import/ReportProduct'));
const ReportEntryFormScreen = lazy(
  () => import('../screens/report-manager/import-product/report-import/ReportEntryForm'),
);
const ReportImportWarehouseScreen = lazy(
  () => import('../screens/report-manager/import-product/report-import/ReportByWarehouse'),
);
const ReportPaymentByTimeScreen = lazy(
  () => import('../screens/report-manager/import-product/report-payment-import/ReportPaymentByTime'),
);
const ReportPaymentByEmployeeScreen = lazy(
  () => import('../screens/report-manager/import-product/report-payment-import/ReportPaymentByEmployee'),
);
const ReportPaymentByWarehouseScreen = lazy(
  () => import('../screens/report-manager/import-product/report-payment-import/ReportPaymentByWarehouse'),
);

const ReportReturnProductByOrderScreen = lazy(
  () => import('../screens/report-manager/report-sales/report-return-product/ReturnProductByOrder'),
);
const ReportReturnProductByProductScreen = lazy(
  () => import('../screens/report-manager/report-sales/report-return-product/ReturnProductByProduct'),
);
const ReportStatisticalByOrderScreen = lazy(
  () => import('../screens/report-manager/report-sales/report-statistical-by/ReportStatisticalByOrder'),
);
const ReportStatisticalByProductScreen = lazy(
  () => import('../screens/report-manager/report-sales/report-statistical-by/ReportStatisticalByProduct'),
);

const ReportSalesPaymentByTimeScreen = lazy(
  () => import('../screens/report-manager/report-sales/report-payment-by/ReportPaymentByTime'),
);
const ReportSalesPaymentByEmployeeScreen = lazy(
  () => import('../screens/report-manager/report-sales/report-payment-by/ReportPaymentByEmployee'),
);
const ReportSalesPaymentByWarehouseScreen = lazy(
  () => import('../screens/report-manager/report-sales/report-payment-by/ReportPaymentByWarehouse'),
);
//Báo cáo giao hàng
const ReportDeliveryByTimeScreen = lazy(() => import('../screens/report-manager/report-delivery/ReportDeliveryByTime'));
const ReportDeliveryByStaffScreen = lazy(
  () => import('../screens/report-manager/report-delivery/ReportDeliveryByStaff'),
);
const ReportDeliveryByDeliveyFormScreen = lazy(
  () => import('../screens/report-manager/report-delivery/ReportDeliveryByDeliveryForm'),
);
const ReportDeliveryByPartnerScreen = lazy(
  () => import('../screens/report-manager/report-delivery/ReportDeliveryByPartner'),
);
const ReportDeliveryByWarehouseScreen = lazy(
  () => import('../screens/report-manager/report-delivery/ReportDeliveryByWarehouse'),
);
const ReportDeliveryByCustomerScreen = lazy(
  () => import('../screens/report-manager/report-delivery/ReportDeliveryByCustomer'),
);
const ReportDeliveryBySourceScreen = lazy(
  () => import('../screens/report-manager/report-delivery/ReportDeliveryBySource'),
);
//Báo cáo tài chính
const ReportFinancialScreen = lazy(() => import('../screens/report-manager/report-financial'));
const ReportFinanceProfitLossScreen = lazy(
  () => import('../screens/report-manager/report-financial/components/ReportFinanceProfitLoss'),
);
const ReportFinanceDebtCustomerScreen = lazy(
  () => import('../screens/report-manager/report-financial/components/ReportFinanceDebtCustomer'),
);
const ReportFinanceDebtSupplierScreen = lazy(
  () => import('../screens/report-manager/report-financial/components/ReportFinanceDebtSupplier'),
);

//Báo cáo khách hàng
const ReportCustomerScreen = lazy(() => import('../screens/report-manager/report-customer'));
// Excel
const ExcelPage = lazy(() => import('../screens/excelHistory'));

const ShipperConnectPartnersPage = lazy(() => import('../screens/shipperConnectPartners'));

//Fake news
const FakeNewsScreen = lazy(() => import('../screens/fake-news'));

export interface IRoutesProperties {
  path: RouteProps['path'];
  name?: string;
  element?: RouteProps['element'];
  permission?: string[];
  children?: IRoutesProperties[];
}

// auth
const authRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.LOGIN,
    name: 'Đăng nhập',
    element: <LoginScreen />,
  },
  {
    path: ROUTE_CONFIG.FORGOT_PASSWORD,
    name: 'Quên mật khẩu',
    element: <FogotPasswordScreen />,
  },
];

// các route public khác
const otherPublicRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.TERMS_OF_USE_PREVIEW,
    element: <TermsOfUse />,
    name: 'Điều khoản sử dụng',
  },

  {
    path: ROUTE_CONFIG.PRIVACY_POLICY_PREVIEW,
    element: <PrivacyPolicy />,
    name: 'Chính sách sử dụng',
  },
];

// dashboard
const dashboardRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.HOME,
    element: <DashboardScreen />,
    name: 'Trang chủ',
    permission: [EPermissionScreen.MENU_TRANG_CHU],
  },
];

// Thông báo
const notificationRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.NOTIFICATION,
    element: <NotificationScreen />,
    name: 'Danh sách thông báo',
    permission: [EPermissionScreen.MENU_THONG_BAO + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.NOTIFICATION_ADD,
    element: <NotificationScreenAdd />,
    name: 'Tạo thông báo',
    permission: [EPermissionScreen.MENU_THONG_BAO + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.NOTIFICATION_UPDATE,
    element: <NotificationScreenUpdate />,
    name: 'Sửa thông báo',
    permission: [
      EPermissionScreen.MENU_THONG_BAO + '_' + EPermissionType.EDIT,
      EPermissionScreen.MENU_THONG_BAO + '_' + EPermissionType.VIEW,
    ],
  },
];

// Quản lý nội dung
const contentRoutes: IRoutesProperties[] = [
  // Banner
  {
    path: ROUTE_CONFIG.BANNER_MANAGER,
    element: <BannerScreen />,
    name: 'Danh sách banner',
    permission: [EPermissionScreen.BANNER + '_' + EPermissionType.VIEW],
  },
  // Onboard
  {
    path: ROUTE_CONFIG.ONBOARD_MANAGER,
    element: <OnboardScreen />,
    name: 'Danh sách Onboard',
    permission: [EPermissionScreen.ONBOARD + '_' + EPermissionType.VIEW],
  },
  // FAQ
  {
    path: ROUTE_CONFIG.FAQ,
    element: <FAQScreen />,
    name: 'Danh sách FAQ',
    permission: [EPermissionScreen.FAQ + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.FAQ_DETAIL,
    element: <FAQScreenDetail />,
    name: 'Chi tiết FAQ',
    permission: [
      EPermissionScreen.FAQ + '_' + EPermissionType.VIEW,
      EPermissionScreen.FAQ + '_' + EPermissionType.EDIT,
    ],
  },
  {
    path: ROUTE_CONFIG.FAQ_ADD,
    element: <FAQScreenAdd />,
    name: 'Tạo FAQ',
    permission: [EPermissionScreen.FAQ + '_' + EPermissionType.ADD],
  },
];

// Quản lý đơn hàng
const orderRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.ORDER_MANAGER_LIST,
    element: <OrderManagerScreen />,
    name: 'Danh sách đơn hàng',
    permission: [EPermissionScreen.DON_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ORDER_MANAGER_ADD,
    element: <AddOrderScreen />,
    name: 'Tạo đơn hàng',
    permission: [EPermissionScreen.DON_HANG + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.ORDER_MANAGER_DETAIL,
    element: <DetailOrderScreen />,
    name: 'Chi tiết đơn hàng',
    permission: [EPermissionScreen.DON_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ORDER_MANAGER_EDIT,
    element: <EditOrderScreen />,
    name: 'Sửa đơn hàng',
    permission: [
      EPermissionScreen.DON_HANG + '_' + EPermissionType.VIEW,
      EPermissionScreen.DON_HANG + '_' + EPermissionType.EDIT,
    ],
  },
  {
    path: ROUTE_CONFIG.ORDER_MANAGER_CUSTOMER_RETURN_PRODUCT,
    element: <CustomerReturnProductScreen />,
    name: 'Danh sách Khách trả hàng',
    permission: [EPermissionScreen.KHACH_TRA_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ORDER_MANAGER_CUSTOMER_RETURN_PRODUCT_ADD,
    element: <AddCustomerReturnProductScreen />,
    name: 'Tạo Khách trả hàng',
    permission: [EPermissionScreen.KHACH_TRA_HANG + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.ORDER_MANAGER_CUSTOMER_RETURN_PRODUCT_DETAIL,
    element: <DetailCustomerReturnProductScreen />,
    name: 'Chi tiết Khách trả hàng',
    permission: [EPermissionScreen.KHACH_TRA_HANG + '_' + EPermissionType.VIEW],
  },
];

// Quản lý kho
const warehouseRoutes: IRoutesProperties[] = [
  // Kho
  {
    path: ROUTE_CONFIG.WARE_HOUSE,
    element: <WarehouseScreen />,
    name: 'Danh sách kho',
    permission: [EPermissionScreen.KHO_HANG + '_' + EPermissionType.VIEW],
  },
  // Nhập hàng
  {
    path: ROUTE_CONFIG.IMPORT_PRODUCTS,
    element: <ImportProductsScreen />,
    name: 'Danh sách Nhập hàng',
    permission: [EPermissionScreen.NHAP_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.IMPORT_PRODUCTS_ADD,
    element: <AddImportProductsScreen />,
    name: 'Tạo Nhập hàng',
    permission: [EPermissionScreen.NHAP_HANG + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.IMPORT_PRODUCTS_EDIT,
    element: <AddImportProductsScreen />,
    name: 'Sửa đơn nhập hàng',
    permission: [EPermissionScreen.NHAP_HANG + '_' + EPermissionType.EDIT],
  },
  {
    path: ROUTE_CONFIG.IMPORT_PRODUCTS_DETAIL,
    element: <DetailImportProductsScreen />,
    name: 'Chi tiết Nhập hàng',
    permission: [EPermissionScreen.NHAP_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ADD_IMPORT_PRODUCTS_COPPY,
    element: <ImportProductsCoppy />,
    name: 'Copy Nhập hàng',
    permission: [EPermissionScreen.NHAP_HANG + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.CREATE_AND_PRINT_BARCODE,
    element: <CreateAndPrintBarcode />,
    name: 'Thiết lập mẫu in tem',
    permission: [EPermissionScreen.NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.RETURN_SUPPLIER,
    element: <ReturnSupplier />,
    name: 'Thêm mới đơn nhập hàng',
    permission: [],
  },
  {
    path: ROUTE_CONFIG.ORDER_FORM,
    element: <OrderFormScreen />,
    name: 'Danh sách đơn đặt hàng',
    permission: [EPermissionScreen.DON_DAT_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ORDER_FORM_ADD,
    element: <AddOrderFormScreen />,
    name: 'Tạo đơn đặt hàng',
    permission: [EPermissionScreen.DON_DAT_HANG + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.ORDER_FORM_DETAIL,
    element: <DetailOrderFormScreen />,
    name: 'Chi tiết đơn đặt hàng',
    permission: [EPermissionScreen.DON_DAT_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ORDER_FORM_EDIT,
    element: <EditOrderFormScreen />,
    name: 'Sửa đơn đặt hàng',
    permission: [EPermissionScreen.DON_DAT_HANG + '_' + EPermissionType.EDIT],
  },
  // Nhà cung cấp
  {
    path: ROUTE_CONFIG.SUPPLIER,
    element: <SupplierScreen />,
    name: 'Danh sách nhà cung cấp',
    permission: [EPermissionScreen.NHA_CUNG_CAP + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.SUPPLIER_DETAIL,
    element: <DetailSupplierScreen />,
    name: 'Chi tiết nhà cung cấp',
    permission: [EPermissionScreen.NHA_CUNG_CAP + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.SUPPLIER_DETAIL_BILLRECEIPT_OR_PAYSLIP,
    element: <DetailSupplierBillScreen />,
    name: 'Phiếu thu chi nhà cung cấp',
    permission: [],
  },
  {
    path: ROUTE_CONFIG.PAYMENT_FORM,
    element: <PaymentFormScreen />,
    name: 'Đơn trả NCC',
    permission: [EPermissionScreen.DON_TRA_NCC],
  },
  // Điều chuyển
  {
    path: ROUTE_CONFIG.TRANSPORT_PRODUCTS,
    element: <TransportProductsScreen />,
    name: 'Danh sách điều chuyển',
    permission: [EPermissionScreen.DIEU_CHUYEN + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.TRANSPORT_PRODUCTS_ADD,
    element: <AddTransportProductsScreen />,
    name: 'Tạo điều chuyển',
    permission: [EPermissionScreen.DIEU_CHUYEN + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.TRANSPORT_PRODUCTS_COPY,
    element: <CopyTransportProductsScreen />,
    name: 'Copy điều chuyển',
    permission: [EPermissionScreen.DIEU_CHUYEN + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.TRANSPORT_PRODUCTS_DETAIL,
    element: <DetailTransportProductsScreen />,
    name: 'Chi tiết điều chuyển',
    permission: [EPermissionScreen.DIEU_CHUYEN + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.PRINT_BARCODE_TRANSPORT,
    element: <PrintBarcodeTransportScreen />,
    name: 'In đơn điều chuyển',
    permission: [],
  },
  //
  {
    path: ROUTE_CONFIG.TRANSPORT_PRODUCTS_CHECK_QUANTITY,
    element: <CheckQuantityTransportProductsScreen />,
    name: 'Thông tin điều chuyển',
    permission: [EPermissionScreen.DIEU_CHUYEN + '_' + EPermissionType.VIEW],
  },
  //
  {
    path: ROUTE_CONFIG.TRANSPORT_PRODUCTS_EDIT,
    element: <EditTransportProductsScreen />,
    name: 'Sửa đơn điều chuyển',
    permission: [EPermissionScreen.DIEU_CHUYEN + '_' + EPermissionType.EDIT],
  },
  //
  {
    path: ROUTE_CONFIG.PAYMENT_FORM,
    element: <PaymentFormScreen />,
    name: 'Sửa đơn điều chuyển',
    permission: [],
  },
];

// Quản lý ưu đãi
const promoRoutes: IRoutesProperties[] = [
  // Sale
  {
    path: ROUTE_CONFIG.SALE,
    element: <SaleScreen />,
    name: 'Danh sách sale',
    permission: [EPermissionScreen.SALE + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.SALE_ADD,
    element: <AddSaleScreen />,
    name: 'Tạo sale',
    permission: [EPermissionScreen.SALE + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.SALE_DETAIL,
    element: <DetailSaleScreen />,
    name: 'Chi tiết sale',
    permission: [EPermissionScreen.SALE + '_' + EPermissionType.VIEW],
  },
  // Flash sale
  {
    path: ROUTE_CONFIG.FLASH_SALE,
    element: <FlashsaleScreen />,
    name: 'Danh sách flash sale',
    permission: [EPermissionScreen.FLASHSALE + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.FLASH_SALE_ADD,
    element: <FlashsaleAddScreen />,
    name: 'Tạo flash sale',
    permission: [EPermissionScreen.FLASHSALE + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.FLASH_SALE_DETAIL,
    element: <FlashsaleDetailScreen />,
    name: 'Chi tiết flash sale',
    permission: [EPermissionScreen.FLASHSALE + '_' + EPermissionType.VIEW],
  },
  // Voucher
  {
    path: ROUTE_CONFIG.VOUCHER_INCENTIVE,
    element: <VoucherIncentiveScreen />,
    name: 'Danh sách voucher',
    permission: [EPermissionScreen.VOUCHER + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.VOUCHER_INCENTIVE_ADD,
    element: <AddVoucherIncentive />,
    name: 'Tạo voucher',
    permission: [EPermissionScreen.VOUCHER + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.VOUCHER_INCENTIVE_DETAIL,
    element: <DetailVoucherIncentive />,
    name: 'Chi tiết voucher',
    permission: [EPermissionScreen.VOUCHER + '_' + EPermissionType.VIEW],
  },
  // Gift
  {
    path: ROUTE_CONFIG.GIVE_A_GIFT,
    element: <GiveAGiftScreen />,
    name: 'Danh sách quà tặng',
    permission: [EPermissionScreen.QUA_TANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.GIVE_A_GIFT_ADD,
    element: <AddGiveAGiftScreen />,
    name: 'Thêm quà tặng',
    permission: [EPermissionScreen.QUA_TANG + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.GIVE_A_GIFT_DETAIL,
    element: <DetailGiveAGiftScreen />,
    name: 'Chi tiết quà tặng',
    permission: [EPermissionScreen.QUA_TANG + '_' + EPermissionType.VIEW],
  },
  // Mua nhiều giảm nhiều
  {
    path: ROUTE_CONFIG.BUY_MORE_DISCOUNT_MORE,
    element: <BuyMoreScreen />,
    name: 'Danh sách mua nhiều',
    permission: [EPermissionScreen.MUA_NHIEU + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.BUY_MORE_DISCOUNT_MORE_ADD,
    element: <AddBuyMoreScreen />,
    name: 'Tạo mua nhiều',
    permission: [EPermissionScreen.MUA_NHIEU + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.BUY_MORE_DISCOUNT_MORE_DETAIL,
    element: <DetailBuyMore />,
    name: 'Chi tiết mua nhiều',
    permission: [EPermissionScreen.MUA_NHIEU + '_' + EPermissionType.VIEW],
  },
];

// Sản phẩm
const productsRoutes: IRoutesProperties[] = [
  // sản phẩm
  {
    path: ROUTE_CONFIG.PRODUCT,
    element: <ProductScreen />,
    name: 'Danh sách sản phẩm',
    permission: [EPermissionScreen.SAN_PHAM + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.PRODUCT_ADD,
    element: <AddNewProductScreen />,
    name: 'Tạo sản phẩm',
    permission: [EPermissionScreen.SAN_PHAM + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.PRODUCT_EDIT,
    element: <DetailProductScreen />,
    name: 'Sửa sản phẩm',
    permission: [EPermissionScreen.SAN_PHAM + '_' + EPermissionType.EDIT],
  },
  {
    path: ROUTE_CONFIG.PRODUCT_DETAIL_EDIT,
    element: <EditDetailProductScreen />,
    name: 'Sửa sản phẩm',
    permission: [EPermissionScreen.SAN_PHAM + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.PRODUCT_COPPY_ADD,
    element: <CopyProductScreen />,
    name: 'Copy sản phẩm',
    permission: [EPermissionScreen.SAN_PHAM + '_' + EPermissionType.ADD],
  },
  // Nhóm sản phẩm
  {
    path: ROUTE_CONFIG.PRODUCT_GROUP,
    element: <ProductGroupScreen />,
    name: 'Danh sách nhóm sản phẩm',
    permission: [EPermissionScreen.NHOM_SAN_PHAM + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ADD_GROUP_PRODUCT,
    element: <AddGroupProductScreen />,
    name: 'Tạo nhóm sản phẩm',
    permission: [EPermissionScreen.NHOM_SAN_PHAM + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.DETAIL_GROUP_PRODUCT,
    element: <DetailGroupProductScreen />,
    name: 'Tạo nhóm sản phẩm',
    permission: [EPermissionScreen.NHOM_SAN_PHAM + '_' + EPermissionType.ADD],
  },
  // Danh mục sản phẩm
  {
    path: ROUTE_CONFIG.PRODUCT_CATEGORY,
    element: <ProductManagerScreen />,
    name: 'Danh sách danh mục sản phẩm',
    permission: [EPermissionScreen.DANH_MUC_SAN_PHAM + '_' + EPermissionType.VIEW],
  },
  // Barcode
  {
    path: ROUTE_CONFIG.PRODUCT_BARCODE,
    element: <ProductBarcode />,
    name: 'Danh sách barcode',
    permission: [],
  },
  {
    path: ROUTE_CONFIG.PRODUCT_BARCODE_ADD,
    element: <AddProductBarcode />,
    name: 'Tạo barcode',
    permission: [],
  },
  {
    path: ROUTE_CONFIG.PRODUCT_BARCODE_DETAIL,
    element: <DetailProductBarcode />,
    name: 'Chi tiết barcode',
    permission: [],
  },
];

// Hướng dẫn sử dụng
const introRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.INSTRUCTION,
    element: <Instruction />,
    name: 'Danh sách hướng dẫn sử dụng',
    permission: [EPermissionScreen.HDSD + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.INSTRUCTION_ADD,
    element: <AddInstruction />,
    name: 'Tạo hướng dẫn sử dụng',
    permission: [EPermissionScreen.HDSD + '_' + EPermissionType.ADD],
  },
  {
    path: ROUTE_CONFIG.INSTRUCTION_DETAIL,
    element: <DetailInstruction />,
    name: 'Chi tiết hướng dẫn sử dụng',
    permission: [EPermissionScreen.HDSD + '_' + EPermissionType.VIEW],
  },
];

// Cấu hình
const configRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.CONFIG_SYSTEM,
    element: <ConfigPage />,
    name: 'Cấu hình',
    permission: [EPermissionScreen.MENU_CAU_HINH + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.TERMS_POLICY,
    element: <TermsOfUseScreen />,
    name: 'Điều khoản và chính sách',
    permission: [EPermissionScreen.DIEU_KHOAN_VA_CHINH_SACH + '_' + EPermissionType.ADD],
  },
];

// Quản lý tài khoản
const accountRoutes: IRoutesProperties[] = [
  // Nhóm admin
  {
    path: ROUTE_CONFIG.GROUP_ADMIN,
    element: <GroupAdminScreen />,
    name: 'Danh sách nhóm Admin',
    permission: [],
  },
  // admin
  {
    path: ROUTE_CONFIG.ADMIN_MANAGER,
    element: <AdminManagerScreen />,
    name: 'Danh sách Admin',
    permission: [],
  },
  // Khách hàng
  {
    path: ROUTE_CONFIG.ACCOUNT_CUSTOMER,
    element: <AccountCustomerScreen />,
    name: 'Danh sách khách hàng',
    permission: [EPermissionScreen.KHACH_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ACCOUNT_CUSTOMER_DETAIL,
    element: <AccountCustomerDetailScreen />,
    name: 'Chi tiết khách hàng',
    permission: [EPermissionScreen.KHACH_HANG + '_' + EPermissionType.VIEW],
  },
  {
    path: ROUTE_CONFIG.ACCOUNT_CUSTOMER_BILL,
    element: <CustomerDetailBillScreen />,
    name: 'Chi tiết phiếu thu chi',
    permission: [EPermissionScreen.KHACH_HANG + '_' + EPermissionType.VIEW],
  },
  // Hạng tài khoản
  {
    path: ROUTE_CONFIG.ACCOUNT_RANK,
    element: <AccountRankScreen />,
    name: 'Danh sách hạng tài khoản',
    permission: [EPermissionScreen.HANG_TAI_KHOAN + '_' + EPermissionType.VIEW],
  },
];

// Báo cáo
const reportRoutes: IRoutesProperties[] = [
  // Báo cáo bán hàng
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES,
    element: <ReportSalesScreen />,
    name: 'Báo cáo bán hàng',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_BY_TIME,
    element: <ReportSalesByTime />,
    name: 'Báo cáo doanh thu theo thời gian',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_BY_EMPLOYEE,
    element: <ReportSalesByStaff />,
    name: 'Báo cáo doanh thu theo nhân viên',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_BY_PRODUCT,
    element: <ReportSalesByProduct />,
    name: 'Báo cáo doanh thu theo sản phẩm',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_BY_ORDER,
    element: <ReportSalesByOrder />,
    name: 'Báo cáo doanh thu theo đơn hàng',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_BY_WAREHOUSE,
    element: <ReportSalesByWarehouse />,
    name: 'Báo cáo doanh thu theo kho',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_BY_SOURCE,
    element: <ReportSalesBySource />,
    name: 'Báo cáo doanh thu theo nguồn',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_BY_CUSTOMER,
    element: <ReportSalesByCustomer />,
    name: 'Báo cáo doanh thu theo khách hàng',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_DELIVERY_BY_TIME,
    element: <ReportDeliveryByTimeScreen />,
    name: 'Báo cáo giao hàng theo thời gian',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_DELIVERY_BY_EMPLOYEE,
    element: <ReportDeliveryByStaffScreen />,
    name: 'Báo cáo giao hàng theo nhân viên',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_DELIVERY_BY_DELIVERY_FORM,
    element: <ReportDeliveryByDeliveyFormScreen />,
    name: 'Báo cáo giao hàng theo vận đơn,phiếu giao ',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_DELIVERY_BY_PARTNER,
    element: <ReportDeliveryByPartnerScreen />,
    name: 'Báo cáo giao hàng theo đối tác',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_DELIVERY_BY_WAREHOUSE,
    element: <ReportDeliveryByWarehouseScreen />,
    name: 'Báo cáo giao hàng theo kho',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_DELIVERY_BY_CUSTOMER,
    element: <ReportDeliveryByCustomerScreen />,
    name: 'Báo cáo giao hàng theo khách hàng',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_DELIVERY_BY_SOURCE,
    element: <ReportDeliveryBySourceScreen />,
    name: 'Báo cáo giao hàng theo nguồn',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },

  //Báo cáo nhập hàng
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT,
    element: <ImportProductScreen />,
    name: 'Báo cáo nhập hàng',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT_REPORT_TIME,
    element: <ReportTimeScreen />,
    name: 'Báo cáo thời gian',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT_REPORT_SUPPLIER,
    element: <ReportSupplierScreen />,
    name: 'Báo cáo nhà cung cấp',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PRODUCT,
    element: <ReportProductScreen />,
    name: 'Báo cáo sản phẩm',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT_REPORT_ENTRY_FORM,
    element: <ReportEntryFormScreen />,
    name: 'Báo cáo đơn nhập',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT_REPORT_WAREHOUSE,
    element: <ReportImportWarehouseScreen />,
    name: 'Báo cáo chi nhánh , kho',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_TIME,
    element: <ReportPaymentByTimeScreen />,
    name: 'Báo cáo nhập hàng thanh toán theo thời gian',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_EMPOLYEE,
    element: <ReportPaymentByEmployeeScreen />,
    name: 'Báo cáo nhập hàng thanh toán theo nhân viên',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_WAREHOUSE,
    element: <ReportPaymentByWarehouseScreen />,
    name: 'Báo cáo nhập hàng thanh toán theo kho',
    permission: [EPermissionScreen.BAO_CAO_NHAP_HANG],
  },

  //phiếu thu
  {
    path: ROUTE_CONFIG.BILL_RECEIPT_DETAIL,
    element: <DetailReceipt />,
    name: 'Chi tiết phiếu thu',
    permission: [EPermissionScreen.PHIEU_THU],
  },
  //phiếu chi
  {
    path: ROUTE_CONFIG.BILL_PAYSLIP_DETAIL,
    element: <DetailPaySlip />,
    name: 'Chi tiết phiếu chi',
    permission: [EPermissionScreen.PHIEU_CHI],
  },
  //Báo cáo trả hàng
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_RETURN_PRODUCT_BY_ORDER,
    element: <ReportReturnProductByOrderScreen />,
    name: 'Báo cáo trả hàng theo đơn hàng',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_RETURN_PRODUCT_BY_PRODUCT,
    element: <ReportReturnProductByProductScreen />,
    name: 'Báo cáo trả hàng theo sản phẩm',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },

  //Báo cáo bán hàng/Thanh toán theo đơn hàng
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_PAYMENT_BY_TIME,
    element: <ReportSalesPaymentByTimeScreen />,
    name: 'Báo cáo thanh toán theo thời gian',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_PAYMENT_BY_EMPLOYEE,
    element: <ReportSalesPaymentByEmployeeScreen />,
    name: 'Báo cáo thanh toán theo nhân viên',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_SALES_PAYMENT_BY_WAREHOUSE,
    element: <ReportSalesPaymentByWarehouseScreen />,
    name: 'Báo cáo thanh toán theo chi nhánh',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  //Báo cáo bán hàng/Thống kê theo đơn hàng

  {
    path: ROUTE_CONFIG.REPORT_MANAGER_STATISTICAL_BY_ORDER,
    element: <ReportStatisticalByOrderScreen />,
    name: 'Báo cáo thống kê theo đơn hàng',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_STATISTICAL_BY_PRODUCT,
    element: <ReportStatisticalByProductScreen />,
    name: 'Báo cáo thống kê theo sản phẩm',
    permission: [EPermissionScreen.BAO_CAO_BAN_HANG],
  },

  //Báo cáo kho
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE,
    element: <ReportWarehouseScreen />,
    name: 'Báo cáo kho',
    permission: [EPermissionScreen.BAO_CAO_KHO],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE_INVENTORY,
    element: <ReportWarehouseInventoryScreen />,
    name: 'Báo cáo tồn kho',
    permission: [EPermissionScreen.BAO_CAO_KHO],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE_INVENTORY_BY_DATE,
    element: <ReportWarehouseInventoryByDateScreen />,
    name: 'Báo cáo tồn kho theo date',
    permission: [EPermissionScreen.BAO_CAO_KHO],
  },

  {
    path: ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE_BOOK,
    element: <ReportWarehouseBookScreen />,
    name: 'Sổ kho',
    permission: [EPermissionScreen.BAO_CAO_KHO],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE_BELOW_NORM,
    element: <ReportInventoryBelowNormScreen />,
    name: 'Báo cáo tồn kho dưới định mức',
    permission: [EPermissionScreen.BAO_CAO_KHO],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE_ABOVE_NORM,
    element: <ReportInventoryAboveNormScreen />,
    name: 'Báo cáo tồn kho trên định mức',
    permission: [EPermissionScreen.BAO_CAO_KHO],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_WAREHOUSE_EXPORT_IMPORT_INVENTORY,
    element: <ReportExportImportInventoryScreen />,
    name: 'Báo cáo xuất nhập tồn',
    permission: [EPermissionScreen.BAO_CAO_KHO],
  },
  //Báo cáo tài chính

  {
    path: ROUTE_CONFIG.REPORT_MANAGER_FINANCE,
    element: <ReportFinancialScreen />,
    name: 'Báo cáo tài chính',
    permission: [EPermissionScreen.BAO_CAO_TAI_CHINH],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_FINANCE_PROFIT_LOSS,
    element: <ReportFinanceProfitLossScreen />,
    name: 'Báo cáo lãi lỗ',
    permission: [EPermissionScreen.BAO_CAO_TAI_CHINH],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_FINANCE_DEBT_CUSTOMER,
    element: <ReportFinanceDebtCustomerScreen />,
    name: 'Báo cáo công nợ khách hàng',
    permission: [EPermissionScreen.BAO_CAO_TAI_CHINH],
  },
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_FINANCE_DEBT_SUPPLIER,
    element: <ReportFinanceDebtSupplierScreen />,
    name: 'Báo cáo công nợ nhà cung cấp',
    permission: [EPermissionScreen.BAO_CAO_TAI_CHINH],
  },

  //Báo cáo khách hàng
  {
    path: ROUTE_CONFIG.REPORT_MANAGER_CUSTOMER,
    element: <ReportCustomerScreen />,
    name: 'Báo cáo khách hàng',
    permission: [EPermissionScreen.BAO_CAO_KHACH_HANG],
  },
];

// Loại phiếu
const billRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.BILL,
    name: 'Phiếu thu',
    permission: [EPermissionScreen.MENU_LOAI_PHIEU],
  },
  // {
  //   path: ROUTE_CONFIG.BILL_RECEIPT,
  //   element: <ReceiptScreen />,
  //   name: 'Phiếu thu',
  //   permission: [EPermissionScreen.PHIEU_THU + '_' + EPermissionType.VIEW],
  // },
  // {
  //   path: ROUTE_CONFIG.BILL_PAYSLIP,
  //   element: <PaySliptScreen />,
  //   name: 'Phiếu chi',
  //   permission: [EPermissionScreen.PHIEU_CHI + '_' + EPermissionType.VIEW],
  // },
  {
    path: ROUTE_CONFIG.BILL_RECEIPT,
    element: <ReceiptScreen />,
    name: 'Phiếu thu',
    permission: [EPermissionScreen.PHIEU_THU + '_' + EPermissionType.VIEW, EPermissionScreen.LOAI_PHIEU_THU],
  },
  {
    path: ROUTE_CONFIG.BILL_PAYSLIP,
    element: <PaySliptScreen />,
    name: 'Phiếu chi',
    permission: [EPermissionScreen.PHIEU_CHI + '_' + EPermissionType.VIEW, EPermissionScreen.LOAI_PHIEU_CHI],
  },
  {
    path: ROUTE_CONFIG.CASH_BOOK,
    element: <CashBookScreen />,
    name: 'Sổ quỹ',
    permission: [EPermissionScreen.BAO_CAO_SO_QUY + '_' + EPermissionType.VIEW],
  },
];

// Lịch sử excel
const excelRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.EXCEL_HISTORY,
    element: <ExcelPage />,
    name: 'Lịch sử Excel',
    permission: [EPermissionScreen.MENU_EXCEL],
  },
];

// Lịch sử shipper
const shipperRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.SHIPPER_CONNECT_PARTNERS,
    element: <ShipperConnectPartnersPage />,
    name: 'Kết nối đối tác',
    permission: [EPermissionScreen.KET_NOI_DOI_TAC],
  },
];

// Quản lý tin Fake
const fakeNewsRoutes: IRoutesProperties[] = [
  {
    path: ROUTE_CONFIG.FAKE_NEWS,
    element: <FakeNewsScreen />,
    name: 'Quản lý tin Fake',
    permission: [],
  },
];

export const flattenRoutes = (routes: IRoutesProperties[]) => {
  let flatRoutes: IRoutesProperties[] = [];
  routes = routes || [];
  for (const item of routes) {
    flatRoutes.push(item);
    if (item.children && item.children.length > 0) {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  }
  return flatRoutes;
};

const authProtectedRoutes = [
  ...dashboardRoutes,
  ...notificationRoutes,
  ...orderRoutes,
  ...contentRoutes,
  ...warehouseRoutes,
  ...promoRoutes,
  ...productsRoutes,
  ...introRoutes,
  ...configRoutes,
  ...accountRoutes,
  ...reportRoutes,
  ...billRoutes,
  ...excelRoutes,
  ...shipperRoutes,
  ...fakeNewsRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

export const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
export const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
