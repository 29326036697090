import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import useToast from 'src/hooks/useToast';
import AccountService from 'src/services/account';
import { useAppDispatch } from 'src/stores';
import localStorageHelper, { KeyStorage } from 'src/utils/localStorage';
import { BaseProps, supperAdminPhones } from '../utils';
import { authProtectedFlattenRoutes } from './app';
import { ROUTE_CONFIG } from './helpers';

interface IProps extends BaseProps {
  roles: string[];
}

const PrivateRoute = (props: IProps) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { user, logout, setAuth, isRoleAdmin } = useAuth();
  const userStore = localStorageHelper.getObject(KeyStorage.USER);
  const dispatch = useAppDispatch();
  const toast = useToast();
  useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async () => {
    try {
      const accountSv = new AccountService();
      const result: any = await accountSv.findAdminDetail(userStore?.id || user?.id, { getDetail: true });
      if (result) {
        setAuth(result);
      } else logout();
    } catch (error) {
      toast.showError(error);
      logout();
    }
  };

  const noPermission = useMemo(() => {
    if (isRoleAdmin) return false;
    if (!user) return true;

    if (location?.pathname === '/') return false;

    if (!user?.account_permission?.length || !location?.pathname) return true;

    // tìm phân quyền
    const route = authProtectedFlattenRoutes.find((x) => x.path === location?.pathname);
    if (!route?.permission) return false;
    const menuPermissions: string[] = route.permission;
    const accountPermissions = user.account_permission.map((x) => x.permission_id);

    const allActivePermissonsExist = accountPermissions.some((permission) => {
      return menuPermissions.includes(permission);
    });

    if (!allActivePermissonsExist) return true;

    // bỏ check với số điện thoại này
    if (location.pathname === '/' || supperAdminPhones.includes(user.phone)) return false;

    return false;
  }, [user, location, isRoleAdmin]);

  // const userHasRequiredRole = account && account.isAdmin && props.roles.includes('user') ? true : false;
  // console.log(account, account?.isAdmin, props.roles, 'userHasRequiredRole');

  if (!user) {
    return <Navigate to={ROUTE_CONFIG.LOGIN} />;
  }

  if (noPermission) {
    return <Navigate to={ROUTE_CONFIG.HOME} />;
  }

  // if (isAuthenticated && !userHasRequiredRole) {
  //   return <Page403 />;
  // }

  return props.children;
};

export default React.memo(PrivateRoute);
