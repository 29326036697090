import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPostDataImport } from './importProduct.state';

const initialState: IPostDataImport = {
    product: []
};

const authSlice = createSlice({
    name: 'import-product',
    initialState,
    reducers: {
        setImportData: (state, { payload }: PayloadAction<any>) => ({
            warehouseId: payload.warehouseId,
            supplierId: payload.supplierId,
            product: payload.product
        }),
        clearImportData: () => {
            return initialState
        }
    },
});

export const { setImportData, clearImportData } = authSlice.actions;

export default authSlice.reducer;
