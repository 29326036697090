export const ROUTE_CONFIG = {
  HOME: '/',

  // Đăng nhập
  LOGIN: '/login',
  CHANGEPASSWORD: '/changePassword',
  TERMS_OF_USE_PREVIEW: '/terms-of-use',
  PRIVACY_POLICY_PREVIEW: '/privacy-policy',
  FORGOT_PASSWORD: '/forgot-password',
  // Quản lý tài khoản
  ACCOUNT: '/account',
  ACCOUNT_CUSTOMER: '/account/customer',
  ACCOUNT_CUSTOMER_DETAIL: '/account/customer/detail',
  ACCOUNT_CUSTOMER_BILL: '/account/customer/bill',
  // Quản lý đơn hàng
  ORDER_MANAGER: '/order-manager',
  ORDER_MANAGER_LIST: '/order-manager/list',
  ORDER_MANAGER_DETAIL: '/order-manager/detail/:id',
  ORDER_MANAGER_DETAIL_NO_ID: '/order-manager/detail/',
  ORDER_MANAGER_ADD: '/order-manager/add-and-delivery',
  ORDER_MANAGER_CUSTOMER_RETURN_PRODUCT: '/order-manager/customer-return-product',
  ORDER_MANAGER_CUSTOMER_RETURN_PRODUCT_ADD: '/order-manager/customer-return-product/add',
  ORDER_MANAGER_CUSTOMER_RETURN_PRODUCT_DETAIL: '/order-manager/customer-return-product/detail',
  ORDER_MANAGER_EDIT: '/order-manager/edit/:id',

  //Hạng tài khoản
  ACCOUNT_RANK: '/account/account-rank',

  //Quản lý sản phẩm
  PRODUCT: '/product-manager/product',
  PRODUCT_ADD: '/product-manager/product/add',
  PRODUCT_EDIT: '/product-manager/product/edit/:productId',
  PRODUCT_EDIT_NO_ID: '/product-manager/product/edit',
  PRODUCT_DETAIL_EDIT: '/product-manager/product/detail/edit/:productId',
  PRODUCT_COPPY_ADD: '/product-manager/product/coppy/add/:productId',
  PRODUCT_MANAGER: '/product-manager/',
  // PRODUCT_GROUP: '/product-group',
  PRODUCT_CATEGORY: '/product-manager/product-category',
  //Nhóm sản phẩm
  PRODUCT_GROUP: '/product-manager/product-group',
  ADD_GROUP_PRODUCT: '/product-manager/product-group/add',
  DETAIL_GROUP_PRODUCT: '/product-manager/product-group/detail/:groupId',
  DETAIL_GROUP_PRODUCT_NO_ID: '/product-manager/product-group/detail',
  // product-barcode
  PRODUCT_BARCODE: '/product-manager/product-barcode',
  PRODUCT_BARCODE_ADD: '/product-manager/product-barcode/add',
  PRODUCT_BARCODE_DETAIL: '/product-manager/product-barcode/detail/:id',

  PRODUCT_SHOW_DASHBOARD: '/product-show-dasboard',
  //Quản lý kho
  WARE_HOUSE_MANAGER: '/warehouse-manager',
  WARE_HOUSE: '/warehouse-manager/warehouse',
  WARE_HOUSE_ADD: '/warehouse-manager/warehouse/add',
  WARE_HOUSE_DETAIL: '/warehouse-manager/warehouse/detail',

  //nhà cung cấp
  SUPPLIER: '/warehouse-manager/supplier',
  SUPPLIER_ADD: '/warehouse-manager/supplier/add',
  SUPPLIER_DETAIL: '/warehouse-manager/supplier/detail',
  SUPPLIER_DETAIL_BILLRECEIPT_OR_PAYSLIP: '/warehouse-manager/supplier/detail/reipt-or-payslip',
  //đơn đặt hàng
  ORDER_FORM: '/warehouse-manager/order-form',
  ORDER_FORM_ADD: '/warehouse-manager/order-form/add',

  ORDER_FORM_DETAIL: '/warehouse-manager/order-form/detail',
  ORDER_FORM_EDIT: '/warehouse-manager/order-form/edit',
  // đơn trả nhà cung cấp
  PAYMENT_FORM: '/warehouse-manager/payment-form',

  //Nhập hàng vào kho
  IMPORT_PRODUCTS: '/warehouse-manager/import-products',
  IMPORT_PRODUCTS_ADD: '/warehouse-manager/import-products/add',
  IMPORT_PRODUCTS_EDIT: '/warehouse-manager/import-products/edit',
  ADD_IMPORT_PRODUCTS_COPPY: '/warehouse-manager/import-products/coppy',
  IMPORT_PRODUCTS_DETAIL: '/warehouse-manager/import-products/detail',
  CREATE_AND_PRINT_BARCODE: '/warehouse-manager/import-products/create-and-print-barcode',
  RETURN_SUPPLIER: '/warehouse-manager/import-products/return-supplier',

  //Điều chuyển hàng
  TRANSPORT_PRODUCTS: '/warehouse-manager/transport-products',
  TRANSPORT_PRODUCTS_ADD: '/warehouse-manager/transport-products/add',
  TRANSPORT_PRODUCTS_DETAIL: '/warehouse-manager/transport-products/detail',
  TRANSPORT_PRODUCTS_COPY: '/warehouse-manager/transport-products/copy',
  PRINT_BARCODE_TRANSPORT: '/warehouse-manager/transport-products/detail/print-barcode-trans',
  TRANSPORT_PRODUCTS_CHECK_QUANTITY: '/warehouse-manager/transport-products/detail/check-quantity',
  TRANSPORT_PRODUCTS_EDIT: '/warehouse-manager/transport-products/edit',

  // quản voucher
  VOUCHER: '/voucher',
  // VOUCHER_DETAIL: '/voucher/detail/:voucherId',
  // VOUCHER_ADD: '/voucher/add',
  //Quản lý ưu đãi khuyến mại
  OFFERS_AND_PROMOTIONS: '/offers-and-promotion',
  INCENTIVE_MANAGER: '/incentive-manager',
  GIFT: '/give-away',

  //Flash sale
  FLASH_SALE: '/incentive-manager/flash-sale',
  FLASH_SALE_ADD: '/incentive-manager/flash-sale/add',
  FLASH_SALE_DETAIL: '/incentive-manager/flash-sale/detail/:flashSaleId',

  // thông báo
  NOTIFICATION: '/notification',
  NOTIFICATION_ADD: '/notification/add',
  NOTIFICATION_UPDATE: '/notification/detail/:notificationId',
  NOTIFICATION_UPDATE_NO_ID: '/notification/detail',
  // Quản lý Ưu Đãi
  SALE: '/incentive-manager/sale',
  SALE_ADD: '/incentive-manager/sale/add',
  SALE_DETAIL: '/incentive-manager/sale/detail/:saleId',
  SALE_DETAIL_NO_ID: '/incentive-manager/sale/detail',
  VOUCHER_INCENTIVE: '/incentive-manager/voucher',
  VOUCHER_INCENTIVE_ADD: '/incentive-manager/voucher/add',
  VOUCHER_INCENTIVE_DETAIL: '/incentive-manager/voucher/detail/:voucherId',
  VOUCHER_INCENTIVE_DETAIL_NO_ID: '/incentive-manager/voucher/detail',
  GIVE_A_GIFT: '/incentive-manager/gift',
  GIVE_A_GIFT_ADD: '/incentive-manager/gift/add',
  GIVE_A_GIFT_DETAIL: '/incentive-manager/gift/detail/:giftId',
  GIVE_A_GIFT_DETAIL_NO_ID: '/incentive-manager/gift/detail',
  BUY_MORE_DISCOUNT_MORE: '/incentive-manager/buy-more-discount-more',
  BUY_MORE_DISCOUNT_MORE_ADD: '/incentive-manager/buy-more-discount-more/add',
  BUY_MORE_DISCOUNT_MORE_DETAIL: '/incentive-manager/buy-more-discount-more/detail/:idBuyMore',
  //Phiếu thu chi
  BILL: '/bill',
  BILL_RECEIPT: '/bill/receipt',
  BILL_RECEIPT_DETAIL: '/bill/receipt/detail/:id',
  BILL_PAYSLIP: '/bill/payslip',
  BILL_PAYSLIP_DETAIL: '/bill/payslip/detail/:id',
  CASH_BOOK: '/bill/cash-book',

  // quản lý nội dung
  CONTENT_MANAGER: '/content-manager',

  //Quản lý banner
  BANNER_MANAGER: '/content-manager/banner',

  PARTNER: '/content-manager/partner',
  ONBOARD_MANAGER: '/content-manager/onboard',
  SPLASH: '/content-manager/splash',
  FAQ: '/content-manager/faq',
  FAQ_DETAIL: '/content-manager/faq/detail/:faqId',
  FAQ_DETAIL_NO_ID: '/content-manager/faq/detail',
  FAQ_ADD: '/content-manager/faq/add',
  TERMS_OF_USE: '/content-manager/terms-of-use',

  PRIVACY_POLICY: '/content-manager/privacy-policy',
  NEWS: '/content-manager/news',

  // Bài đăng
  POST: '/post',
  POST_MANAGER: '/post/post-manager',

  //Báo cáo
  REPORT_MANAGER: '/report-manager',
  REPORT_MANAGER_WAREHOUSE: '/report-manager/warehouse',
  REPORT_MANAGER_WAREHOUSE_INVENTORY: '/report-manager/warehouse/inventory',
  REPORT_MANAGER_WAREHOUSE_INVENTORY_BY_DATE: '/report-manager/warehouse/inventory-by-date',
  REPORT_MANAGER_WAREHOUSE_BOOK: '/report-manager/warehouse/book',
  REPORT_MANAGER_WAREHOUSE_BELOW_NORM: '/report-manager/warehouse/below-norm',
  REPORT_MANAGER_WAREHOUSE_ABOVE_NORM: '/report-manager/warehouse/above-norm',
  REPORT_MANAGER_WAREHOUSE_EXPORT_IMPORT_INVENTORY: '/report-manager/warehouse/inventory-export-import',

  //Báo cáo bán hàng
  REPORT_MANAGER_SALES: '/report-manager/sales',
  REPORT_MANAGER_SALES_BY_TIME: '/report-manager/sales/by-time',
  REPORT_MANAGER_SALES_BY_EMPLOYEE: '/report-manager/sales/by-employee',
  REPORT_MANAGER_SALES_BY_PRODUCT: '/report-manager/sales/by-product',
  REPORT_MANAGER_SALES_BY_ORDER: '/report-manager/sales/by-order',
  REPORT_MANAGER_SALES_BY_WAREHOUSE: '/report-manager/sales/by-warehouse',
  REPORT_MANAGER_SALES_BY_SOURCE: '/report-manager/sales/by-source',
  REPORT_MANAGER_SALES_BY_CUSTOMER: '/report-manager/sales/by-customer',
  REPORT_MANAGER_RETURN_PRODUCT_BY_ORDER: '/report-manager/sales/return-product-by-order',
  REPORT_MANAGER_RETURN_PRODUCT_BY_PRODUCT: '/report-manager/sales/return-product-by-product',
  REPORT_MANAGER_STATISTICAL_BY_ORDER: '/report-manager/sales/statistical-by-order',
  REPORT_MANAGER_STATISTICAL_BY_PRODUCT: '/report-manager/sales/statistical-by-product',

  REPORT_MANAGER_SALES_PAYMENT_BY_TIME: '/report-manager/sales/payment-by-time',
  REPORT_MANAGER_SALES_PAYMENT_BY_EMPLOYEE: '/report-manager/sales/payment-by-employee',
  REPORT_MANAGER_SALES_PAYMENT_BY_WAREHOUSE: '/report-manager/sales/payment-by-warehouse',

  //Báo cáo giao hàng
  REPORT_MANAGER_DELIVERY_BY_TIME: '/report-manager/delivery-by-time',
  REPORT_MANAGER_DELIVERY_BY_EMPLOYEE: '/report-manager/delivery-by-employee',
  REPORT_MANAGER_DELIVERY_BY_DELIVERY_FORM: '/report-manager/delivery-by-delivery-form',
  REPORT_MANAGER_DELIVERY_BY_PARTNER: '/report-manager/delivery-by-partner',
  REPORT_MANAGER_DELIVERY_BY_WAREHOUSE: '/report-manager/delivery-by-warehouse',
  REPORT_MANAGER_DELIVERY_BY_CUSTOMER: '/report-manager/delivery-by-customer',
  REPORT_MANAGER_DELIVERY_BY_SOURCE: '/report-manager/delivery-by-source',

  //Báo cáo nhập hàng
  REPORT_MANAGER_IMPORT_PRODUCT: '/report-manager/import-product',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_TIME: '/report-manager/import-product/report-time',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_SUPPLIER: '/report-manager/import-product/report-supplier',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PRODUCT: '/report-manager/import-product/report-product',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_ENTRY_FORM: '/report-manager/import-product/report-entry-form',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_WAREHOUSE: '/report-manager/import-product/report-warehouse',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_TIME: '/report-manager/import-product/report-payment-by-time',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_EMPOLYEE: '/report-manager/import-product/report-payment-by-employee',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_WAREHOUSE:
    '/report-manager/import-product/report-payment-by-warehouse',
  //Báo cáo tài chính
  REPORT_MANAGER_FINANCE: '/report-manager/report-finance',
  REPORT_MANAGER_FINANCE_PROFIT_LOSS: '/report-manager/report-finance/profit-and-loss',
  REPORT_MANAGER_FINANCE_DEBT_CUSTOMER: '/report-manager/report-finance/debt-customer',
  REPORT_MANAGER_FINANCE_DEBT_SUPPLIER: '/report-manager/report-finance/debt-supplier',
  //Báo cáo khách hàng
  REPORT_MANAGER_CUSTOMER: '/report-manager/import-product/report-customer',
  //Vận chuyển
  SHIPPER: '/shipper',
  SHIPPER_CONNECT_PARTNERS: '/shipper/connect-partners',
  // Địa bàn
  AREA: '/area',
  COMMUNE: '/area/commune',
  DISTRICT: '/area/district',
  PROVINCE: '/area/province',

  // config
  CONFIG: '/config',
  CONFIG_SYSTEM: '/config/system',
  TERMS_POLICY: '/config/terms-policy',

  // quản lý khu vực
  AREA_MANAGER: '/activity-area',
  ADD_AREA_LIST: '/activity-area/add',
  EDIT_AREA_LIST: '/activity-area/edit/:id',
  ACTIVITY_AREA: '/activity-area/activity-area-config',
  AREA_LIST: '/activity-area',

  LIST_ACCOUNT_CUSTOMER: '/activity-area/find-customer/:id',

  LIST_TRIP_BY_AREA: '/activity-area/booking/:id',

  // quản lý tin tức
  NEWS_ADD: '/content-manager/news/add',
  NEWS_DETAIL: '/content-manager/news/detail/:newsId',
  NEWS_EDIT: '/content-manager/news/edit/',
  //khiếu nại khuyến nghị
  COMPLAIN_MANAGER: '/complain-manager',
  COMPLAIN_DETAIL: '/complain-detail/:id',

  // quản lý nhóm tài khoản admin
  ADMIN_GROUP_ADD: '/account/admin-group/add',
  ADMIN_GROUP_DETAIL: '/account/admin-group/add/:id',
  ADMIN_GROUP_EDIT: '/account/admin-group/edip/',
  ADMIN_MANAGER: '/account/admin',

  //hướng dẫn sử dụng
  INSTRUCTION: '/instruction',
  INSTRUCTION_ADD: '/instruction/add',
  INSTRUCTION_DETAIL: '/instruction/detail/:instructionId',
  INSTRUCTION_DETAIL_NO_ID: '/instruction/detail',
  //Nhóm Admin
  GROUP_ADMIN: '/account/group-admin',

  // quản lý nhóm tài khoản admin
  ADMIN_GROUP_MEMBER: '/admin-group-member',

  CHAT: '/chat',
  EXCEL_HISTORY: '/excel-history',

  // quản lý tin fake
  FAKE_NEWS: '/fake-news',
};

export const ROUTE_CONFIG_FOR_AREA = {
  NOTIFICATION: ROUTE_CONFIG.NOTIFICATION,
  NOTIFICATION_ADD: ROUTE_CONFIG.NOTIFICATION_ADD,
  AREA_LIST: ROUTE_CONFIG.AREA_LIST,
  LIST_ACCOUNT_CUSTOMER: ROUTE_CONFIG.LIST_ACCOUNT_CUSTOMER,
  LOGIN: ROUTE_CONFIG.LOGIN,
};
