import { ISelect } from 'src/utils/common.enum';

export enum EExcelType {
  CUSTOMER = 1, // Khách hàng
  ORDER = 2, // đơn hàng
  RETURN_PRODUCT = 3, // trả hàng
  IMPORT = 4, // Nhập hàng
  PURCHASE_ORDER = 5, // Đặt hàng
  TRANSFER = 6, // Điều chuyển
  PRODUCTS = 7, // Sản phẩm
  DEBT_HISTORY = 8, // Lịch sử công nợ ncc
  IMPORT_CUSTOMER = 9, // Nhập khách hàng
  DEBT_CUSTOMER = 10, // Lịch sử công nợ khách hàng
  IMPORT_PRODUCT = 11, // Nhập sản phẩm
  REPORT_STORE_REVENUE = 12, // Báo cáo doanh thu cửa hàng
  REPORT_ORDER_PAYMENT = 13, // Báo cáo thanh toán đơn hàng
  REPORT_ORDER = 14, // Báo cáo thống kê đơn hàng
  REPORT_PRODUCT = 15, // Báo cáo thống kê sản phẩm
  REPORT_INVENTORY = 16, // Báo cáo thống kê tồn kho
  REPORT_INVENTORY_BY_DATE = 17, // Báo cáo thống kê tồn kho theo date
  REPORT_IMPORT = 18, // Báo cáo nhập hàng
  REPORT_IMPORT_PAYMENT = 19, // Báo cáo thanh toán nhập hàng
  REPORT_WAREHOUSE_BOOK = 20, // Báo cáo sổ kho
  REPORT_INVENTORY_BELOW_THE_NORM = 21, // Báo cáo tồn kho dưới định mức
  REPORT_INVENTORY_EXCEED_THE_NORM = 22, // Báo cáo tồn kho vượt định mức
  INCOMING_LIST = 23, // Danh sách hàng đang về
  REPORT_INVENTORY_IMPORT_AND_EXPORT = 24, // Báo cáo xuất, nhập tồn kho
  REPORT_LIST_PROFIT_AND_LOSS = 25, //Danh sách giao dịch trong báo cáo lãi lỗ
  REPORT_PROFIT_AND_LOSS = 26, //Báo cáo lãi lỗ
  REPORT_CUSTOMER_DEBT = 27, //Báo công nợ  khách hàng
  REPORT_SUPPLIER_DEBT = 28, //Báo công nợ ncc
  TRADING_LIST = 29, //Danh sách hàng đang giao dịch
  CASH_BOOK = 30, //Danh sách sổ quỹ, phiếu thu, chi
  REPORT_DELIVERY = 31, //Báo cáo giao hàng
  REPORT_CUSTOMER_DEBT_DETAIL = 32, //công nợ khách hàng chi tiết
  REPORT_SUPPLIER_DEBT_DETAIL = 33, //Báo công nợ ncc chi tiết
  REPORT_PROFIT_AND_LOSS_ORTHER = 34, //Báo cáo lãi lỗ (Khác)
  ACCOUNTING = 35, // Hạch toán trong báo cáo lãi lỗ
}

export enum EExcelStatus {
  WAIT = 'WAIT',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL',
}

export const excelHistoryName = {
  [EExcelType.CUSTOMER]: 'Khách hàng',
  [EExcelType.ORDER]: 'Đơn hàng',
  [EExcelType.RETURN_PRODUCT]: 'Trả hàng',
  [EExcelType.IMPORT]: 'Nhập hàng',
  [EExcelType.PURCHASE_ORDER]: 'Đặt hàng',
  [EExcelType.TRANSFER]: 'Điều chuyển',
  [EExcelType.PRODUCTS]: 'Sản phẩm',
  [EExcelType.DEBT_HISTORY]: 'Lịch sử công nợ NCC',
  [EExcelType.IMPORT_CUSTOMER]: 'Nhập khách hàng',
  [EExcelType.DEBT_CUSTOMER]: 'Lịch sử công nợ KH',
  [EExcelType.IMPORT_PRODUCT]: 'Nhập sản phẩm',
  [EExcelType.REPORT_STORE_REVENUE]: 'Báo cáo doanh thu cửa hàng',
  [EExcelType.REPORT_ORDER_PAYMENT]: 'Báo cáo thanh toán đơn hàng',
  [EExcelType.REPORT_ORDER]: 'Báo cáo thống kê đơn hàng',
  [EExcelType.REPORT_PRODUCT]: 'Báo cáo thống kê sản phẩm',
  [EExcelType.REPORT_INVENTORY]: 'Báo cáo thống kê tồn kho',
  [EExcelType.REPORT_INVENTORY_BY_DATE]: 'Báo cáo thống kê tồn kho theo ngày',
  [EExcelType.REPORT_IMPORT]: 'Báo cáo nhập hàng',
  [EExcelType.REPORT_IMPORT_PAYMENT]: 'Báo cáo thanh toán nhập hàng',
  [EExcelType.REPORT_WAREHOUSE_BOOK]: 'Báo cáo sổ kho',
  [EExcelType.REPORT_INVENTORY_BELOW_THE_NORM]: 'Báo cáo tồn kho dưới định mức',
  [EExcelType.REPORT_INVENTORY_EXCEED_THE_NORM]: 'Báo cáo tồn kho vượt định mức',
  [EExcelType.INCOMING_LIST]: 'Danh sách hàng đang về',
  [EExcelType.REPORT_INVENTORY_IMPORT_AND_EXPORT]: 'Báo cáo xuất nhập tồn',
  [EExcelType.REPORT_LIST_PROFIT_AND_LOSS]: 'Danh sách giao dịch trong báo cáo lãi lỗ',
  [EExcelType.REPORT_PROFIT_AND_LOSS]: 'Báo cáo lãi lỗ',
  [EExcelType.REPORT_CUSTOMER_DEBT]: 'Báo cáo công nợ khách hàng',
  [EExcelType.REPORT_CUSTOMER_DEBT_DETAIL]: 'Báo cáo chi tiết công nợ khách hàng',
  [EExcelType.REPORT_SUPPLIER_DEBT_DETAIL]: 'Báo cáo chi tiết công nợ nhà cung cấp',
  [EExcelType.REPORT_SUPPLIER_DEBT]: 'Báo cáo công nợ nhà cung cấp',
  [EExcelType.TRADING_LIST]: 'Danh sách hàng đang giao dịch',
  [EExcelType.CASH_BOOK]: 'Sổ quỹ',
  [EExcelType.REPORT_DELIVERY]: 'Báo cáo giao hàng',
  [EExcelType.REPORT_PROFIT_AND_LOSS_ORTHER]: 'Chi tiết hạch toán báo cáo lãi lỗ',
  [EExcelType.ACCOUNTING]: 'Hạch toán báo cáo lãi lỗ',
};

export const excelStatusName = {
  [EExcelStatus.WAIT]: 'Đang xử lý',
  [EExcelStatus.FINISH]: 'Hoàn thành',
  [EExcelStatus.CANCEL]: 'Đã hủy',
};

export const excelStatusColor = {
  [EExcelStatus.WAIT]: '#f99426',
  [EExcelStatus.FINISH]: '#43c844',
  [EExcelStatus.CANCEL]: '#ff0000',
};

export const excelStatusOpts: ISelect[] = [
  { value: EExcelStatus.WAIT, label: 'Đang xử lý' },
  { value: EExcelStatus.FINISH, label: 'Hoàn thành' },
  { value: EExcelStatus.CANCEL, label: 'Đã hủy' },
];

export const exceTypeOpts: ISelect[] = [
  { value: EExcelType.CUSTOMER, label: 'Khách hàng' },
  { value: EExcelType.ORDER, label: 'Đơn hàng' },
  { value: EExcelType.RETURN_PRODUCT, label: 'Trả hàng' },
  { value: EExcelType.IMPORT, label: 'Nhập hàng' },
  { value: EExcelType.PURCHASE_ORDER, label: 'Đặt hàng' },
  { value: EExcelType.TRANSFER, label: 'Điều chuyển' },
  { value: EExcelType.PRODUCTS, label: 'Sản phẩm' },
  { value: EExcelType.DEBT_HISTORY, label: 'Lịch sử công nợ NCC' },
  { value: EExcelType.IMPORT_CUSTOMER, label: 'Nhập khách hàng' },
  { value: EExcelType.DEBT_CUSTOMER, label: 'Lịch sử công nợ KH' },
  { value: EExcelType.IMPORT_PRODUCT, label: 'Nhập sản phẩm' },
  { value: EExcelType.REPORT_STORE_REVENUE, label: 'Báo cáo doanh thu cửa hàng' },
  { value: EExcelType.REPORT_ORDER_PAYMENT, label: 'Báo cáo thanh toán đơn hàng' },
  { value: EExcelType.REPORT_ORDER, label: 'Báo cáo thống kê đơn hàng' },
  { value: EExcelType.REPORT_PRODUCT, label: 'Báo cáo thống kê sản phẩm' },
  { value: EExcelType.REPORT_INVENTORY, label: 'Báo cáo thống kê tồn kho' },
  { value: EExcelType.REPORT_INVENTORY_BY_DATE, label: 'Báo cáo thống kê tồn kho theo ngày' },
  { value: EExcelType.REPORT_IMPORT, label: 'Báo cáo nhập hàng' },
  { value: EExcelType.REPORT_IMPORT_PAYMENT, label: 'Báo cáo thanh toán nhập hàng' },
  { value: EExcelType.REPORT_WAREHOUSE_BOOK, label: 'Báo cáo sổ kho' },
  { value: EExcelType.REPORT_INVENTORY_BELOW_THE_NORM, label: 'Báo cáo tồn kho dưới định mức' },
  { value: EExcelType.REPORT_INVENTORY_EXCEED_THE_NORM, label: 'Báo cáo tồn kho vượt định mức' },
  { value: EExcelType.REPORT_INVENTORY_IMPORT_AND_EXPORT, label: 'Báo cáo xuất nhập tồn' },
  { value: EExcelType.REPORT_PROFIT_AND_LOSS, label: 'Báo cáo xuất lãi lỗ' },
  { value: EExcelType.REPORT_DELIVERY, label: 'Báo cáo giao hàng' },
  { value: EExcelType.CASH_BOOK, label: 'Sổ quỹ' },
];

export interface IExcelHistoryReq {
  pageSize: number;
  pageIndex: number;
  searchText?: string;
  status?: EExcelStatus;
  type?: EExcelType;
  sortBy?: string;
  order?: string;
}

export interface IExcelHistory {
  id: number;
  name: string;
  type: EExcelType;
  status: EExcelStatus;
  created_at: Date;
  updated_at: Date;
  isImport: boolean;
  success: string;
  fail: string;
  loading?: boolean;
}
